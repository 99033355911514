import ResponsiveDrawer from "../../components/AuthDrawer";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded';
import {useEffect, useRef, useState} from "react";
import {Box, Button, FormControl, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import DinoDialog from "../../components/Dialog";
import Loading from "../../components/Loading";
import useCallDataApi from "../../hooks/data";
import DinoDataGrid from "../../components/DataGrid";
import {getFormData} from "../../utils/formUtils";
import thousandSeparator from "../../utils/NumberUtils";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {useHistory} from "react-router-dom";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import {hu} from "date-fns/locale";

const Controlling = () => {
    const {getData, postData, updateData} = useCallDataApi('general-ledger-analytics')
    const {getData: getGroup} = useCallDataApi('controlling-group')
    const [importDataDialogOpen, setImportDataDialogOpen] = useState(false)
    const [commentDialogOpen, setCommentDialogOpen] = useState(false)
    const [selectedMonth, setSelectedMonth] = useState(localStorage.getItem('month-select') || new Date().getMonth() + 1)
    const [selectedYear, setSelectedYear] = useState(localStorage.getItem('year-select') || new Date().getFullYear())
    const [selectedPlace, setSelectedPlace] = useState(localStorage.getItem('place-select') || 'KEF')
    const [fromDate, setFromDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [current, setCurrent] = useState(-1)
    const [data, setData] = useState([])
    const [selectedGroup, setSelectedGroup] = useState(parseInt(localStorage.getItem('group-select')) || -1)
    const [groups, setGroups] = useState([])
    const [loading, setLoading] = useState(true)
    const loadDataRef = useRef(null)
    const commentForm = useRef(null)
    const history = useHistory()

    const loadDataFromMRC = () => {
        const data = getFormData(loadDataRef.current)
        setLoading(true)
        postData('load_from_mrc/', data).then(d => window.location.reload()).finally(() => setLoading(false))
    }

    const updateRowColor = (id, color) => {
        postData(`color_item/`, {color: color, id: id}).then(editedRow => {
            const editedDataList = data.map(row => {
                if (row.id === id) return {...row, ...editedRow}
                return row;
            });
            setData(editedDataList)
        })
    }

    const newOrEditComment = () => {
        const newComment = getFormData(commentForm.current)
        postData('set_comment/', {id: current, comment: newComment?.comment}).then(editedRow => {
            const editedDataList = data.map(row => {
                if (row.id === current) return {...row, ...editedRow}
                return row;
            });
            setData(editedDataList)
        })
    }

    const handleRowClick = (itemNumber) => {
        history.push(`/item-number/${btoa(itemNumber)}`)
    }

    useEffect(() => {
        document.title = 'Alaptábla'
        // if (selectedGroup === -1) getData().then(d => setData(d)).finally(() => setLoading(false))
        getGroup().then(g => setGroups(g))
    }, [])

    useEffect(() => {
        setLoading(true)
        postData('get_with_filter/', {group: selectedGroup, year: selectedYear, month: selectedMonth, place: selectedPlace}).then(d => setData(d)).finally(() => setLoading(false))
    }, [selectedGroup, selectedYear, selectedMonth, selectedPlace])

    const extraButtons = [
        {
            name: 'Adatok importálása',
            props: {
                variant: 'contained',
                endIcon: <FileDownloadRoundedIcon/>,
                onClick: () => setImportDataDialogOpen(true)
            }
        }
    ]
    const filterButtons = [
        {
            name: 'Tételek',
            props: {
                variant: 'contained',
                onClick: () => history.push('/controlling')
            }
        },
        {
            name: 'Munkaszámok',
            props: {
                variant: 'contained',
                onClick: () => history.push('/item-number')
            }
        }
    ]
    const columns = [
        {
            field: "manage",
            headerName: "Manage",
            sortable: false,
            type: "actions",
            getActions: (params) => [<GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'red')
                }}
                label="Problémás"
                className='super-app-theme--red'
                showInMenu={true}
            />,<GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'green')
                }}
                label="Nem jutalék"
                className='super-app-theme--green'
                showInMenu={true}
            />,<GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'blue')
                }}
                label="Rossz jogcím"
                className='super-app-theme--blue'
                showInMenu={true}
            />,<GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'yellow')
                }}
                label="Rendben"
                className='super-app-theme--yellow'
                showInMenu={true}
            />,<GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'purple')
                }}
                label="Visszamenőleges javítás"
                className='super-app-theme--purple'
                showInMenu={true}
            />,<GridActionsCellItem
                icon={<ColorLensRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, 'pink')
                }}
                label="Már nem jutalék alapja"
                className='super-app-theme--pink'
                showInMenu={true}
            />,<GridActionsCellItem
                icon={<ClearRoundedIcon/>}
                onClick={() => {
                    updateRowColor(params.row.id, null)
                }}
                label="Szín törlése"
                showInMenu={true}
            />,<GridActionsCellItem
                icon={<EditRoundedIcon/>}
                onClick={() => {
                    setCommentDialogOpen(true)
                    setCurrent(params.row.id)
                }}
                label="Komment"
                showInMenu={true}
            />]
        },
        // {headerName: 'id', field: 'id'},
        {headerName: 'Munkaszám', field: 'MUNKASZAM'},
        {headerName: 'Cikk megnevezés', field: 'ckname'},
        {headerName: 'Tétel megnevezés', field: 'mirr_name1'},
        {headerName: 'Főkönyvi szám', field: 'mirr_code'},
        {headerName: 'Ügyfélnév', field: 'UGYFEL_NEV'},
        {headerName: 'Bizonylat azonosító', field: 'BIZ_AZONOS'},
        {headerName: 'Bizonylat iktatószám', field: 'BIZ_IKTATO'},
        {headerName: 'Költséghely név', field: 'KTGHELY'},
        {headerName: 'Tétel megjegyzés', field: 'MEGJEGYZES'},
        {headerName: 'Napló megnevezés', field: 'LOGNAME'},
        {headerName: 'Deviza', field: 'DEVIZA'},
        {headerName: 'Teljesítés dátuma', field: 'TELJDAT', type: 'date'},
        {headerName: 'Kiállítás dátuma', field: 'KIALLDAT', type: 'date'},
        {headerName: 'Áfa kód', field: 'AFAKOD'},
        {headerName: 'Árfolyam', field: 'ARFOLYAM', type: 'number'},
        {headerName: 'T/K', field: 'mirr_type'},
        {headerName: 'Devizás összeg', field: 'DEV_OSSZ', type: 'number'},
        {headerName: 'Tartozik összeg', field: 'T_OSSZ', type: 'number'},
        {headerName: 'Követel összeg', field: 'K_OSSZ', type: 'number'},
        {headerName: 'Szín', field: 'color'},
        {headerName: 'Komment', field: 'comment'},
        {
            headerName: 'Egyenleg',
            field: 'summed_gross_value',
            type: 'number',
            valueGetter: params => params.row?.gross_value,
            renderCell: params => {
                if (params.row['gross_value'] > 0) return <p style={{color: 'green'}}>{thousandSeparator(params.row['gross_value'])}</p>
                else return <p style={{color: 'red'}}>{thousandSeparator(params.row['gross_value'])}</p>
            }
        },
    ]
    return <ResponsiveDrawer label='Alaptábla' filterButtons={filterButtons} buttons={extraButtons}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: {xs: 'column', md: 'row'},
                justifyContent: 'flex-end',
                marginBottom: 2,
                gap: 2,
            }}>
                <FormControl>
                    <Select
                        id="place-select"
                        value={selectedPlace}
                        onChange={e => {
                            const selected = e.target.value
                            localStorage.setItem('place-select', selected)
                            setSelectedPlace(selected)
                        }}
                    >
                        <MenuItem value='KEF'>KEF</MenuItem>
                        <MenuItem value='Vállalati általános'>Vállalati általános</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <Select
                        id="year-select"
                        value={selectedYear}
                        onChange={e => {
                            const selected = e.target.value
                            localStorage.setItem('year-select', selected)
                            setSelectedYear(selected)
                        }}
                    >
                        <MenuItem value={2023}>2023</MenuItem>
                        <MenuItem value={2024}>2024</MenuItem>
                        <MenuItem value={2025}>2025</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <Select
                        id="month-select"
                        value={selectedMonth}
                        onChange={e => {
                            const selected = e.target.value
                            localStorage.setItem('month-select', selected)
                            setSelectedMonth(selected)
                        }}
                    >
                        <MenuItem value={1}>Jan</MenuItem>
                        <MenuItem value={2}>Feb</MenuItem>
                        <MenuItem value={3}>Mar</MenuItem>
                        <MenuItem value={4}>Apr</MenuItem>
                        <MenuItem value={5}>Maj</MenuItem>
                        <MenuItem value={6}>Jun</MenuItem>
                        <MenuItem value={7}>Jul</MenuItem>
                        <MenuItem value={8}>Aug</MenuItem>
                        <MenuItem value={9}>Szep</MenuItem>
                        <MenuItem value={10}>Okt</MenuItem>
                        <MenuItem value={11}>Nov</MenuItem>
                        <MenuItem value={12}>Dec</MenuItem>
                    </Select>
                </FormControl>
                <FormControl>
                    <Select
                        id="group-select"
                        value={selectedGroup}
                        onChange={e => {
                            const selected = e.target.value
                            setSelectedGroup(selected)
                            localStorage.setItem('group-select', selected)
                        }}
                    >
                        <MenuItem value={-1}>Összes csoport</MenuItem>
                        {groups.map(g => <MenuItem key={g.id} value={g.id}>{g.name}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
            <DinoDataGrid rows={data} columns={columns} onRowClick={params => handleRowClick(params.MUNKASZAM)}/>

        <DinoDialog open={importDataDialogOpen} handleClose={() => setImportDataDialogOpen(false)}
                    title='Adatok importálása' subtitle='Válaszd ki mikori adatokat szeretnél importálni'
                    actions={<Stack direction='row' spacing={3}>
                        <Button variant='outlined' onClick={() => setImportDataDialogOpen(false)}>Mégsem</Button>
                        <Button variant='contained' endIcon={<FileDownloadRoundedIcon/>}
                                onClick={() => {
                                    setImportDataDialogOpen(false)
                                    loadDataFromMRC()
                                }}>Adatok importálása</Button>
                    </Stack>}>
            <Stack spacing={1}>
                <Typography variant='subtitle2' color='#062B45'>Idősáv</Typography>
                <Typography variant='body1' color='#38556A'>Válaszd ki mikori adatokat szeretnél importálni</Typography>
                <form ref={loadDataRef}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hu}>
                        <Stack spacing={2} direction='row'>
                            <DatePicker
                                inputFormat="yyyy-MM-dd"
                                mask='____-__-__'
                                value={fromDate}
                                onChange={(v) => setFromDate(v)}
                                renderInput={(params) => <TextField name='from_date' variant='standard' {...params} />}
                            />
                            <Box sx={{alignItems: 'center', justifyContent: 'center'}}>
                                <Typography variant='body1' color='#38556A'>-</Typography>
                            </Box>
                            <DatePicker
                                inputFormat="yyyy-MM-dd"
                                mask='____-__-__'
                                value={endDate}
                                onChange={(v) => setEndDate(v)}
                                renderInput={(params) => <TextField name='until_date' variant='standard' {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </form>
            </Stack>
        </DinoDialog>

        <DinoDialog open={commentDialogOpen}
                    handleClose={() => setCommentDialogOpen(false)}
                    title='Komment módosítása'
                    subtitle='Ird ide a megjegyzésedet a tételhez'
                    actions={<Stack direction='row' spacing={2}>
                        <Button variant='outlined' onClick={() => setCommentDialogOpen(false)}>Mégsem</Button>
                        <Button variant='contained' startIcon={<EditRoundedIcon/>} onClick={() => {
                            newOrEditComment()
                            setCommentDialogOpen(false)
                        }}>Komment módosítása</Button>
                    </Stack>}>
            <form ref={commentForm}>
                <Stack spacing={2}>
                    <Typography variant='overline'>Komment</Typography>
                    <TextField name='nullable-comment' variant='standard' defaultValue={data.find(d => d.id === current)?.comment}/>
                </Stack>
            </form>
        </DinoDialog>


        <Loading isLoading={loading}/>
    </ResponsiveDrawer>
}

export default Controlling
