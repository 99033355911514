import AuthDrawer from "../../components/AuthDrawer";
import useCallDataApi from "../../hooks/data";
import {useEffect, useState} from "react";
import {Avatar, Box, Card, CardActions, CardContent, Grid, Stack, Typography, useTheme} from "@mui/material";
import Loading from "../../components/Loading";
import Button from "@mui/material/Button";
import {stringAvatar} from "../../utils/miscUtils";
import {useHistory} from "react-router-dom";
import { Person as PersonIcon, OpenInNew as OpenInNewIcon, CalendarToday as CalendarTodayIcon, AccessTime as AccessTimeIcon } from '@mui/icons-material';



const SavedCommissions = () => {
    const {getData: getUsers} = useCallDataApi('users')
    const {getData: getSavedCommissions} = useCallDataApi('saved-commissions')
    const [users, setUsers] = useState([])
    const [commissions, setCommissions] = useState([])
    const [loading, setLoading] = useState(true)
    const theme = useTheme()
    const history = useHistory()

    document.title = 'Ügyintézői jutalék'

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const [u, c] = await Promise.all([
                getUsers(),
                getSavedCommissions()
            ])
            if (u) setUsers(u)
            if (c) setCommissions(c)
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOpenCommission = (commission) => {
        history.push(`/saved-commission/${commission?.id}`)
    }

    const getUser = id => users.find(u => u?.id === id)

    return <AuthDrawer label='Mentett ügyintézői jutalék'>
            <Grid container spacing={2}>
                {commissions?.map(c => <Grid key={c.id} item xs={12} md={6} xl={6}>
                    <Card sx={{ maxWidth: 400, m: 2, p: 2, boxShadow: 3 }}>
                        <CardContent>
                            <Stack direction="row" spacing={3} alignItems="center">
                                <Avatar {...stringAvatar(getUser(c?.user)?.name)} sx={{ width: 70, height: 70 }} />
                                <Box>
                                    <Typography variant="h6" color="primary" sx={{ display: 'flex', alignItems: 'center' }}>
                                        {getUser(c?.user)?.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                                        <CalendarTodayIcon sx={{ mr: 1 }} />
                                        {c?.year}-{c?.month}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center' }}>
                                        <AccessTimeIcon sx={{ mr: 1 }} />
                                        Mentve: {c?.saved_at}
                                    </Typography>
                                </Box>
                            </Stack>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'flex-end' }}>
                            <Button
                                variant="outlined"
                                onClick={() => handleOpenCommission(c)}
                                startIcon={<OpenInNewIcon />}
                            >
                                Megnyitás
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>)}
            </Grid>

        <Loading isLoading={loading}/>

    </AuthDrawer>
}

export default SavedCommissions