import ResponsiveDrawer from "../../../components/AuthDrawer";
import NewBarChart from "../../../components/charts/NewBarChart";
import thousandSeparator from "../../../utils/NumberUtils";
import NewPieChart from "../../../components/charts/NewPieChart";
import NewLineChart from "../../../components/charts/NewLineChart";
import DinoDataGrid from "../../../components/DataGrid";

import {
    Box, Chip,
    FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";

import {useState, useEffect} from "react";

import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {hu} from "date-fns/locale";
import useCallDataApi from "../../../hooks/data";
import Loading from "../../../components/Loading";
import {format} from "date-fns";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import GoogleBarChart from "../../../components/charts/GoogleBarChart";
import GooglePieChart from "../../../components/charts/GooglePieChart";
import GoogleLineChart from "../../../components/charts/GoogleLineChart";


const TicketReport = () => {
    document.title = 'Repjegy kimutatás'
    const today = new Date()
    const getYearsFrom = fromYear => {
        let todaysDate = new Date();
        const thisYear = parseInt(todaysDate.getFullYear())
        let i = fromYear
        const response = []
        while (i <= thisYear) {
            response.push(i.toString())
            i++
        }
        return response
    }
    const {postData} = useCallDataApi('galileo-data')
    const {getData: getUsers} = useCallDataApi('users')
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const [fromDate, setFromDate] = useState(new Date(`${today.getFullYear()}-01-01`))
    const [endDate, setEndDate] = useState(new Date())
    const [compareYear, setCompareYear] = useState(2023)
    const [selectedYears, setSelectedYears] = useState(getYearsFrom(2021));

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedYears(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };



    useEffect(() => {
        setLoading(true)
        getUsers().then(u => setUsers(u))
        postData('ticket_report/', {start_date: format(fromDate, 'yyyy-MM-dd'), end_date: format(endDate, 'yyyy-MM-dd'), selected_years: selectedYears, compare: compareYear})
            .then(r => setData(r))
            .finally(() => setLoading(false))
    }, [fromDate, endDate, selectedYears, compareYear])

    const findByMrcIdUser = uid => users.find(u => u?.mrc_user_id === parseInt(uid, 10))

    const columns = [
        {
            field: 'szla_ugyi_id',
            headerName: 'Ügyintéző',
            width: 150,
            renderCell: p => findByMrcIdUser(p.row.szla_ugyi_id)?.name
        },
        {
            field: 'total_sum',
            headerName: 'Összesen',
            width: 200,
            type: 'number',
            valueFormatter: ({ value }) => value.toLocaleString()
        },
        {
            field: 'count',
            headerName: 'Darabszám',
            width: 150,
            type: 'number'
        }
    ]

    const columnsExtra = [
        {
            field: 'szla_ugyi_id',
            headerName: 'Ügyintéző',
            width: 150,
            renderCell: p => findByMrcIdUser(p.row.szla_ugyi_id)?.name
        },
        {
            field: 'extra_sum',
            headerName: 'Extra',
            width: 200,
            type: 'number',
            valueFormatter: ({ value }) => thousandSeparator(value) + ' Ft'
        },
        {
            field: 'service_sum',
            headerName: 'Szervízdíj',
            width: 150,
            type: 'number',
            valueFormatter: ({ value }) => thousandSeparator(value) + ' Ft'
        },
        {
            field: 'extra_plus_service',
            headerName: 'Összesen',
            width: 150,
            type: 'number',
            valueFormatter: ({ value }) => thousandSeparator(value) + ' Ft'
        }
    ]

    const columnsChange = [
        {
            field: 'partner_id',
            headerName: 'Cég id',
            width: 150
        },
        {
            field: 'partner_name',
            headerName: 'Cégnév',
            width: 150
        },
        {
            field: 'current_total',
            headerName: 'Aktuális összesen',
            width: 200,
            type: 'number',
            valueFormatter: ({ value }) => thousandSeparator(value) + ' Ft'
        },
        {
            field: 'prev_total',
            headerName: 'Választott összesen',
            width: 150,
            type: 'number',
            valueFormatter: ({ value }) => thousandSeparator(value) + ' Ft'
        },
        {
            field: 'change_absolute',
            headerName: 'Változás',
            width: 150,
            type: 'number',
            valueFormatter: ({ value }) => thousandSeparator(value) + ' Ft'
        },
        {
            field: 'change_percent',
            headerName: 'Változás mértéke',
            width: 150,
            type: 'number',
            valueFormatter: ({ value }) => thousandSeparator(Math.round(value * 100) / 100) + ' %'
        }
    ]


    const ticketPercent = (data?.extra_service_sum / data?.ticket_sum) * 100;

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const years = ['2021', '2022', '2023', '2024']
    const topPartnersOptions = {
        title: "Top megrendelők",
        chartArea: { width: "30%" },
        hAxis: {
            title: "Időszakos repülőjegy forgalom",
            minValue: 0,
            format: '#,### Ft'
        },
        vAxis: {
            title: "Partner",
        },
    };
    const topAirlinesOptions = {
        title: "Top légitársaságok",
        chartArea: { width: "30%" },
        hAxis: {
            title: "Időszakos repülőjegy forgalom",
            minValue: 0,
            format: '#,### Ft'
        },
        vAxis: {
            title: "Légitársaság",
        },
    };
    const topEmployeesOptions = {
        title: "Ügyintézők Extra és Szervízdíj",
        chartArea: { width: "30%" },
        isStacked: true,
        hAxis: {
            title: "Időszakos forgalom",
            minValue: 0,
            format: '#,### Ft'
        },
        vAxis: {
            title: "Ügyintéző",
        },
    };
    const monthlyOptions = {
        title: "Jet forgalom havi bontásban",
        chartArea: { width: "30%" },
        isStacked: true,
        hAxis: {
            title: `${compareYear} forgalom`,
            minValue: 0,
            format: '#,### Ft'
        },
        vAxis: {
            title: "Hónap",
        },
    };

    return <ResponsiveDrawer label='Repjegy kimutatás'>
        <div>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hu}>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: {xs: 'column', md: 'row'},
                    justifyContent: 'flex-end',
                    marginBottom: 2,
                    gap: 2,
                }}>
                    <DatePicker
                        inputFormat="yyyy-MM-dd"
                        mask='____-__-__'
                        value={fromDate}
                        onChange={(v) => setFromDate(v)}
                        renderInput={(params) => <TextField name='from_date'  {...params} />}
                    />
                    <Typography variant='body1' color='#38556A'>-</Typography>
                    <DatePicker
                        inputFormat="yyyy-MM-dd"
                        mask='____-__-__'
                        value={endDate}
                        onChange={(v) => setEndDate(v)}
                        renderInput={(params) => <TextField name='until_date'  {...params} />}
                    />
                </Box>
            </LocalizationProvider>
            {/*<Typography variant='h4' textAlign='center'>Top Megrendelők</Typography>*/}
            <GoogleBarChart height='400px' options={topPartnersOptions} data={data?.top_partners?.slice(0, 13)}/>

            <Stack spacing={2} className='gradient-card' marginBottom={2} marginTop={2}>
                <Typography variant='headline6'>Összesítő</Typography>
                <Stack justifyContent='space-between' direction={{xs: 'column', md: 'row'}}>
                    <Stack spacing={1}>
                        <Typography variant='overline'>Összes jegy</Typography>
                        <Typography variant='subtitle2'>{thousandSeparator(data?.ticket_sum)} Ft</Typography>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography variant='overline'>Eladott jegy</Typography>
                        <Typography variant='subtitle2'>{thousandSeparator(data?.ticket_number)} db</Typography>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography variant='overline'>Átlag jegyár</Typography>
                        <Typography variant='subtitle2'>{thousandSeparator(data?.avg_ticket_price)} db</Typography>
                    </Stack>
                </Stack>
            </Stack>
            {/*<Typography variant='h4' textAlign='center'>Top Légitársaságok</Typography>*/}
            <GoogleBarChart height='400px' options={topAirlinesOptions} data={data?.airline_toplist?.slice(0, 13)}/>
            {/*<NewBarChart data={mapAirlines(data?.airline_toplist?.slice(0, 12))} tooltipFormatter={tooltipFormatter}*/}
            {/*             yAxisDataKey='airline' barDatas={[{barDataKey: 'total_sum', barName: 'Forgalom'}]}/>*/}

            <Stack spacing={2} className='gradient-card' marginBottom={2} marginTop={2}>
                <Typography variant='headline6'>Összesítő</Typography>
                <Stack justifyContent='space-between' direction={{xs: 'column', md: 'row'}}>
                    {data?.type?.map(t => <Stack spacing={1}>
                        <Typography variant='overline'>{t?.tipus}</Typography>
                        <Typography variant='subtitle2'>Összesen: {thousandSeparator(t?.total_sum)} Ft</Typography>
                        <Typography variant='subtitle2'>Mennyiség: {thousandSeparator(t?.count)} db</Typography>
                        <Typography variant='subtitle2'>Átlag ár: {thousandSeparator(Math.round(t?.avg_sum))} Ft</Typography>
                    </Stack>)}
                </Stack>
            </Stack>

            <GooglePieChart data={data?.tipus_aggregates_chart} options={{title: 'Repülőjegy darab szerint'}}/>

            <Stack direction={{xs: 'column', md: 'row'}} spacing={2} marginBottom={2} marginTop={2}>
                <GooglePieChart data={data?.airline_toplist} options={{title: 'Légitársaság megoszlás'}}/>
                <GooglePieChart data={data?.airline_toplist_fapad} options={{title: 'Fapados légitársaság megoszlás'}}/>
            </Stack>


            <Typography variant='h4' textAlign='center'>Ügyintézői Toplista</Typography>
            {data?.agent_performance && <DinoDataGrid rows={data?.agent_performance} columns={columns} name='agent-performance'
                           getRowId={(row) => row?.szla_ugyi_id}/>}

            {/*<Typography variant='h4' textAlign='center' marginTop={2}>Évek összehasonlítása</Typography>*/}
            <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-chip-label">Évek</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={selectedYears}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {years.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <GoogleLineChart options={{
                title: "Évek összehasonlítása",
                curveType: "function",
                legend: { position: "bottom" },
            }} data={data?.monthly_totals_by_years}/>
            <Box marginTop={2}/>
            {/*<NewLineChart tooltipFormatter={tooltipFormatter} data={data?.monthly_totals_by_years} dataKeys={selectedYears}/>*/}

            {/*<Typography variant='h4' textAlign='center'>Ügyintézői Toplista</Typography>*/}
            <GoogleBarChart height='600px' data={data?.agent_performance_extra_service_chart} options={topEmployeesOptions}/>
            {/*<NewBarChart tooltipFormatter={tooltipFormatter} data={data?.agent_performance_extra_service} yAxisDataKey='szla_ugyi_id' yAxisTickFormatter={yAxisTickFormatter} barDatas={[{barDataKey: 'extra_sum', barName: 'Extra'}, {barDataKey: 'service_sum', barName: 'Szervízdíj'}]} />*/}
            <Typography variant='h4' textAlign='center' marginTop={2}>Ügyintézői Toplista (Extra | Szervízdíj)</Typography>
            {data?.agent_performance_extra_service && <DinoDataGrid rows={data?.agent_performance_extra_service} columns={columnsExtra} name='agent-performance-extra'
                                                      getRowId={(row) => row?.szla_ugyi_id}/>}


            <Stack spacing={2} className='gradient-card' marginBottom={2} marginTop={2}>
                <Typography variant='headline6'>Összesítő</Typography>
                <Stack justifyContent='space-between' direction={{xs: 'column', md: 'row'}}>
                    <Stack spacing={1}>
                        <Typography variant='overline'>Extra + szerviz</Typography>
                        <Typography variant='subtitle2'>{thousandSeparator(data?.extra_service_sum)} Ft</Typography>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography variant='overline'>Százalékosan</Typography>
                        <Typography variant='subtitle2'>{thousandSeparator(Math.round(ticketPercent * 100) / 100)} %</Typography>
                    </Stack>
                </Stack>
            </Stack>

            <Typography variant='h4' textAlign='center'>Partner forgalom változás</Typography>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Év választása</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={compareYear}
                    label="Év választása"
                    onChange={e => setCompareYear(e.target.value)}
                >
                    <MenuItem value={2021}>2021</MenuItem>
                    <MenuItem value={2022}>2022</MenuItem>
                    <MenuItem value={2023}>2023</MenuItem>
                    <MenuItem value={2024}>2024</MenuItem>
                </Select>
            </FormControl>
            {data?.calculate_total_expression && <DinoDataGrid rows={data?.calculate_total_expression} columns={columnsChange} name='agent-performance-extra'
                                                                    getRowId={(row) => row?.partner_id}/>}
            <Box marginTop={2}/>
            <GoogleBarChart data={data?.monthly_traffic_chart} height='600px' options={monthlyOptions}/>

        </div>
        <Loading isLoading={loading}/>
    </ResponsiveDrawer>
}

export default TicketReport