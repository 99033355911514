import {useHistory, useParams} from "react-router-dom";
import {Box, FormControl, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import {useEffect, useState} from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {hu} from "date-fns/locale";
import {format} from "date-fns";
import useCallDataApi from "../../../hooks/data";
import thousandSeparator from "../../../utils/NumberUtils";
import DinoDataGrid from "../../../components/DataGrid";
import MyPieChart from "../../employee/PieChart";
import {getYears} from "../../../utils/miscUtils";
import MonthlyPartnerBarChart from "../../partnerTraffic/MonthlyPartnerBarChart";
import Loading from "../../../components/Loading";
import AuthDrawer from "../../../components/AuthDrawer";


const CompanyGroupDetail = () => {
    const today = new Date()
    const {getData: fetchPartners, postData: postPartners} = useCallDataApi('company-groups')
    const [partner, setPartner] = useState({})
    const [chartTrafficData, setChartTrafficData] = useState([])
    const [chartMarginData, setChartMarginData] = useState([])
    const [chartItemData, setChartItemData] = useState([])
    const [partnerData, setPartnerData] = useState([])
    const [monthly, setMonthly] = useState([])
    const [fromDate, setFromDate] = useState(new Date(`${today.getFullYear()}-01-01`))
    const [endDate, setEndDate] = useState(today)
    const [selectedYear, setSelectedYear] = useState(today.getFullYear())
    const [loading, setLoading] = useState(true)
    const params = useParams()
    const history = useHistory()

    document.title = partner?.name
    // const yearList = Array.from({ length: today.getFullYear() - 2022 }, (_, index) => 2023 + index)

    useEffect(() => {
        fetchPartners(`${params.id}/`).then(p => {
            setPartner(p)
        }).finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        postPartners('partner_data_for_range/', {
            partner: params.id,
            start_date: format(fromDate, 'yyyy-MM-dd'),
            end_date: format(endDate, 'yyyy-MM-dd'),
            // mode: selectedYear
        }).then(d => {
            setPartnerData(d)
        })
    }, [fromDate, endDate])

    useEffect(() => {
        postPartners('monthly_partner_data_for_year/', {
            partner: params.id,
            year: selectedYear,
        }).then(d => setMonthly(d))
        postPartners(`partner_traffic_by_controlling_groups/`, {year: selectedYear, partner: params.id})
            .then(p => setChartTrafficData(p))
        postPartners(`partner_margin_by_controlling_groups/`, {year: selectedYear, partner: params.id})
            .then(p => setChartMarginData(p))
        postPartners(`partner_item_by_controlling_groups/`, {year: selectedYear, partner: params.id})
            .then(p => setChartItemData(p))
    }, [selectedYear])

    const columns = [
        {headerName: 'Tétel megnevezése', field: 'mirr_name1', width: 300},
        {
            headerName: 'Forgalom',
            field: 'total_sum_traffic',
            width: 300,
            renderCell: p => `${thousandSeparator(p.row.total_sum_traffic)} Ft`
        },
        {
            headerName: 'Árrés',
            field: 'total_sum_margin',
            width: 300,
            renderCell: p => `${thousandSeparator(p.row.total_sum_margin)} Ft`
        },
        {
            headerName: 'Darabszám',
            field: 'total_count',
            width: 300,
            renderCell: p => `${thousandSeparator(p.row.total_count)} db`
        },
    ]

    const sumTotalSumTraffic = () => {
        let s = 0
        partnerData.forEach(p => s += p?.total_sum_traffic)
        return s
    }

    const sumTotalSumMargin = () => {
        let s = 0
        partnerData.forEach(p => s += p?.total_sum_margin)
        return s
    }

    const sumTotalCount = () => {
        let s = 0
        partnerData.forEach(p => s += p?.total_count)
        return s
    }

    return <AuthDrawer label={partner.name}>
        <Stack direction='row'>
            <Button variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>}
                    onClick={() => history.goBack()}>Vissza</Button>
        </Stack>
        <Stack marginBottom={3} className='gradient-card' spacing={1} marginTop={2}>
            <Typography variant='headline6'>Összesen</Typography>
            <Typography variant='overline'>Forgalom</Typography>
            <Typography variant='subtitle2'>{thousandSeparator(sumTotalSumTraffic())} Ft</Typography>
            <Typography variant='overline'>Árrés</Typography>
            <Typography variant='subtitle2'>{thousandSeparator(sumTotalSumMargin())} Ft</Typography>
            <Typography variant='overline'>Darabszám</Typography>
            <Typography variant='subtitle2'>{thousandSeparator(sumTotalCount())} db</Typography>
        </Stack>
        <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 2,
            gap: 3,
            marginTop: 3
        }}>
            <Box sx={{
                display: 'flex',
                gap: 3,
            }}>
                {/*<Button variant='outlined' startIcon={showModifiers ? <VisibilityOffRoundedIcon/> : <VisibilityRoundedIcon/>} onClick={() => setShowModifiers(prev => !prev)}>Vonal alatti tételek {showModifiers ? 'elrejtése' : 'mutatása'}</Button>*/}
                {/*<Button variant='outlined' startIcon={<AddCircleRoundedIcon/>} onClick={() => setModifierNewOpen(true)}>Vonal alatti tétel hozzáadása</Button>*/}
            </Box>
            <Box sx={{
                display: 'flex',
                gap: 3,
            }}>
                {/*<FormControl>*/}
                {/*    <Select*/}
                {/*        id="margin-traffic-select"*/}
                {/*        value={selectedYear}*/}
                {/*        onChange={e => setSelectedMode(e.target.value)}*/}
                {/*    >*/}
                {/*        <MenuItem value='margin'>Árrés</MenuItem>*/}
                {/*        <MenuItem value='traffic'>Forgalom</MenuItem>*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={hu}>
                    <Stack spacing={2} direction='row' alignItems='center'>
                        <DatePicker
                            inputFormat="yyyy-MM-dd"
                            mask='____-__-__'
                            value={fromDate}
                            onChange={(v) => setFromDate(v)}
                            renderInput={(params) => <TextField name='from_date' {...params} />}
                        />
                        <Typography variant='body1' color='#38556A'>-</Typography>
                        <DatePicker
                            inputFormat="yyyy-MM-dd"
                            mask='____-__-__'
                            value={endDate}
                            onChange={(v) => setEndDate(v)}
                            renderInput={(params) => <TextField name='until_date' {...params} />}
                        />
                    </Stack>
                </LocalizationProvider>
            </Box>
        </Box>
        <DinoDataGrid rows={partnerData} columns={columns}/>
        <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 2,
            gap: 3,
            marginTop: 3
        }}>
            <Box sx={{
                display: 'flex',
                gap: 3,
            }}>
                {/*<Button variant='outlined' startIcon={showModifiers ? <VisibilityOffRoundedIcon/> : <VisibilityRoundedIcon/>} onClick={() => setShowModifiers(prev => !prev)}>Vonal alatti tételek {showModifiers ? 'elrejtése' : 'mutatása'}</Button>*/}
                {/*<Button variant='outlined' startIcon={<AddCircleRoundedIcon/>} onClick={() => setModifierNewOpen(true)}>Vonal alatti tétel hozzáadása</Button>*/}
            </Box>
            <Box sx={{
                display: 'flex',
                gap: 3,
            }}>
                <FormControl>
                    <Select
                        id="margin-traffic-select"
                        value={selectedYear}
                        onChange={e => setSelectedYear(e.target.value)}
                    >
                        {getYears().map(y => <MenuItem key={y} value={y}>{y}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
        </Box>
        <Box className="white-card" marginTop={2}>
            <Typography variant='headline6'>Havi bontás</Typography>
            <MonthlyPartnerBarChart data={monthly}/>
            {/*<MyPieChart data={chartData}/>*/}
        </Box>
        <Box className="white-card" marginTop={2}>
            <Typography variant='headline6'>Csoportos forgalom bontás</Typography>
            <MyPieChart data={chartTrafficData}/>
        </Box>
        <Box className="white-card" marginTop={2}>
            <Typography variant='headline6'>Csoportos árrés bontás</Typography>
            <MyPieChart data={chartMarginData}/>
        </Box>
        <Box className="white-card" marginTop={2}>
            <Typography variant='headline6'>Csoportos darabszám bontás</Typography>
            <MyPieChart data={chartItemData} unit='db'/>
        </Box>

        <Loading isLoading={loading}/>
    </AuthDrawer>
}

export default CompanyGroupDetail